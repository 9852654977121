.gradient_background {
    background: linear-gradient(90deg, #ff0000,#ffcd39, #00ff00);
}
.gradient_button {
    width: 9.0909%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    float:left;
    background-color: white;
}

